<template>
  <div class="role-container">
    <filter-bar @searched="filter"></filter-bar>
    <v-layout row align-start justify-start nowrap class="list-group-container">
      <list
        :name="list.name"
        :sts="filterList(sts, list.filter)"
        :filter="list.filter"
        v-for="list in listConfig"
        :key="list.name"
        @dragged="moveOrder"
      ></list>
    </v-layout>
  </div>
</template>

<script>
import list from '@/components/boards/list.vue'
import filterBar from '@/components/bars/filterbar.vue'
export default {
  name: 'processing',
  components: {
    list,
    filterBar
  },
  computed: {
    sts () {
      return this.$store.state.orders.salesOrders
    },

    user () {
      return this.$store.state.profile.user
    }
  },
  data () {
    return {
      listConfig: [
        { name: 'Waiting to be tested', filter: 0 },
        { name: 'Testing', filter: 1 },
        { name: '', filter: 2 },
        { name: 'Brought to shipping', filter: 3 }
      ],
      searchable: ''
    }
  },
  methods: {
    filterList (orders, status) {
      // return a list that is filtered by status and by searchables.searched
      const list = []
      let checker = false

      for (const order of orders) {
        if (order.status === status) {
          for (const key in order) {
            if ((String(order[key]).toLowerCase()).indexOf(this.searchable.toLowerCase()) !== -1) {
              checker = true
            }
          }
          if (checker) {
            list.push(order)
            checker = false
          }
        }
      }
      return list
    },

    filter (test) {
      this.searchable = test
    },

    moveOrder (order, status) {
      order.status = status
      if (status === 0) {
        order.shipper = null
        order.packager = null
      } else {
        if (!order.packager) {
          order.packager = this.user.initials
        } else if (!order.shipper) {
          order.shipper = this.user.initials
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
